/*
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import ShapedImage from '../shaped-image';
import Image from '../image';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Hero = ( {
    children, imageName, imageAlt, imageCaption, isCentered, noHeader, standardHeight, regularImage, borderImage,
} ) => {
    const classes = classNames( 'hero', { 'is-centered': isCentered, 'no-header': noHeader, 'is-standard-height': standardHeight } );

    return (
        <div className={ classes }>
            <div className="hero-outer container">
                <div className="hero-inner row flex-md-row-reverse">
                    { imageName && imageAlt && (
                        <div className="hero-image col-12 col-lg-4">
                            <figure>
                                { regularImage ? (
                                    <div style={ borderImage ? { border: '4px solid white' } : {} }>
                                        <Image name={imageName} alt={imageAlt} />
                                    </div>
                                ) : (
                                    <div style={ borderImage ? { border: '1px solid white' } : {} }>
                                        <ShapedImage name={imageName} alt={imageAlt} grayscale />
                                    </div>
                                )}
                                { imageCaption && <figcaption>{ imageCaption }</figcaption> }
                            </figure>
                        </div>
                    ) }
                    { children && (
                        <div className="hero-content col-12 col-lg-8">
                            { children }
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    imageName: PropTypes.string,
    imageAlt: PropTypes.string,
    imageCaption: PropTypes.string,
    children: PropTypes.node.isRequired,
    isCentered: PropTypes.bool,
    noHeader: PropTypes.bool,
    standardHeight: PropTypes.bool,
    regularImage: PropTypes.bool,
    borderImage: PropTypes.bool,
};

Hero.defaultProps = {
    imageName: '',
    imageAlt: '',
    imageCaption: '',
    isCentered: false,
    noHeader: false,
    standardHeight: false,
    regularImage: false,
    borderImage: false,
};

export default Hero;
