/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Hero from '../../components/hero';
import Layout from '../../components/layout';
import Section from '../../components/section';
import SEO from '../../components/seo';
import ButtonGroup from '../../components/button-group';
import Button from '../../components/button';
import Blockquote from '../../components/blockquote';
import OGImage from '../../images/pricing-freelance-projects-og-image.png';

const PricingFreelanceProjectsPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            noSignUpForm
            noCta
            hideHeader
            hideLinks
        >
            <SEO
                title="Pricing Freelance Projects eBook"
                description="The story of how I priced myself to a six-figure yearly freelance career from scratch."
                imageAlt="Pricing Freelance Projects eBook"
                imageUrl={ `https://www.tomhirst.com${OGImage}` }
            />
            <Hero
                imageName="pricing-freelance-projects-3d.png"
                imageAlt="Pricing Freelance Projects"
                imageCaption="Using value realistically to know what to charge and get paid what you're worth."
                regularImage
            >
                <h1>Pricing Freelance Projects</h1>
                <p><strong>Pricing Freelance Projects</strong> is the story of how I priced myself to a <strong>six-figure yearly
                    freelance career</strong> from scratch.</p>
                <p><strong>Everything that I&apos;ve learned</strong> in over a decade of <strong>pricing real-world freelance</strong> work is in this book.</p>
                <p>If you want to know:</p>
                <ul>
                    <li><FontAwesomeIcon icon="check" /><strong>What to charge for your work</strong></li>
                    <li><FontAwesomeIcon icon="check" /><strong>Which pricing methods work best</strong></li>
                    <li><FontAwesomeIcon icon="check" /><strong>How to get paid what you&apos;re worth</strong></li>
                </ul>
                <p>Then <strong>Pricing Freelance Project</strong> is for <u>you</u>.</p>
                <ButtonGroup>
                    <Button to="https://gum.co/pcUlz?wanted=true" icon="download"
                        text="Buy eBook now for $39" color="green" external gumroadSingle />
                    <Button to="#keep-reading" icon="arrow-down" text="Keep reading" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="keep-reading">Backstory</h2>
                <p>I posted a <a href="https://twitter.com/tom_hirst/status/1277933730078785537"><strong>Twitter
                    thread</strong></a> summarising my expertise in pricing freelance projects and it <strong>went viral</strong>.</p>

                <p>At the time of writing, the thread has received <strong>37,100 likes</strong> and <strong>11,200 retweets</strong>.</p>

                <p>My message has been seen over <strong>3,000,000</strong> times by people.</p>

                <p>Its content has been re-worked and <strong>re-posted around the world</strong>.</p>

                <p>I’ve had <strong>countless messages of thanks</strong> and <strong>praise</strong> for sharing my super-condensed version of 11 years+
                    in pricing freelance work.</p>

                <p>I’d already thought about writing a book on pricing for freelancers, but this response was the
                    catalyst. <strong>I had to write this book</strong>.</p>

                <p>I’ve made it my mission to <strong>understand pricing deeply</strong> to <strong>get paid what I’m worth throughout my career</strong> and this makes me <strong>a great living from freelancing</strong> year on year.</p>

                <p><strong>Pricing Freelance Projects</strong> is not "just another value-based pricing system".</p>

                <p>This book shows you how to <strong>understand</strong>, <strong>raise</strong> and <strong>retain your value</strong> on a per-project basis.</p>

                <p>This book teaches you how to <strong>apply value realistically</strong> to <strong>know what to charge</strong> and <strong>get paid what
                    you&apos;re worth</strong>.</p>

                <p>This book <strong>expands heavily</strong> on the <strong>Twitter thread</strong> that freelancers loved.</p>

                <p>This book contains <strong>everything that I know about pricing freelance work</strong> and there&apos;s a <strong>ton of value</strong> in
                    it for <u>you</u>.</p>
                <Button className="gumroad-button" to="https://gum.co/pcUlz?wanted=true" icon="download"
                        text="Buy eBook now for $39" color="green" external gumroadSingle />
                <h2>You&apos;ll Learn</h2>
                <ul>
                    <li>How to get a handle on <strong>pricing your work with confidence</strong></li>
                    <li>About the <strong>real-world projects</strong> that helped forged my <strong>success as a freelancer</strong></li>
                    <li>Why <strong>typical approaches</strong> to pricing <strong>aren&apos;t always the best</strong></li>
                    <li>The <strong>methods</strong> that I use to <strong>earn more money in less time</strong></li>
                    <li>How to start <strong>thinking with a value mindset</strong> when giving prices</li>
                    <li>How to <strong>increase</strong>, <strong>raise</strong> and <strong>retain</strong> your <strong>freelance value</strong></li>
                    <li>What <strong>clients are thinking</strong> when they look for valuable <strong>freelancers to hire</strong></li>
                    <li>The <strong>pros</strong> and <strong>cons</strong> of <strong>every popular pricing methodology</strong> for freelance work</li>
                    <li>The <strong>system</strong> that I use to <strong>protect myself from giving bad prices</strong></li>
                    <li>How to use value to <strong>know what to charge</strong></li>
                    <li>How to use value to <strong>get paid what you&apos;re worth</strong></li>
                </ul>
                <Button className="gumroad-button" to="https://gum.co/pcUlz?wanted=true" icon="download"
                        text="Buy eBook now for $39" color="green" external gumroadSingle />
                <h2>Praise From Readers</h2>
                <div className="blockquote-list">
                    <Blockquote
                        quote="Wow! <strong>By far the best book I’ve read about pricing</strong> consulting services."

                        cite="Aleyda Solis, SEO Consultant"
                        imageName="as2-headshot.jpg"
                        imageAlt="Aleyda Solis"
                        largeQuote
                    />
                    <Blockquote
                        quote="<strong>Reading this book will save you time and earn you money</strong>."
                        cite="Tom Hoadley, Freelance Front-End Developer"
                        imageName="th-headshot.jpg"
                        imageAlt="Tom Hoadley"
                        largeQuote
                    />
                    <Blockquote
                        quote="
                        Give this book a read! The <strong>wealth of knowledge shared is applicable to you</strong> - wherever you may be in your freelancing journey."
                        cite="Anyi Ndongko, Freelance Creative Designer"
                        imageName="an-headshot.jpg"
                        imageAlt="Anyi Ndongko"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Using the advice from this book <strong>I made more money in my first month freelancing than I did from my last month of salary</strong>."

                        cite="Dan Spratling, Freelance Web Developer"
                        imageName="ds-headshot.jpg"
                        imageAlt="Dan Spratling"
                        mediumQuote
                    />
                    <Blockquote
                        quote="After 3 years of freelancing, I thought I had pricing my work figured out. Turns out I didn&apos;t. <strong>Game changer</strong>."

                        cite="Jordan Gilroy, Freelance Graphic Designer"
                        imageName="jg-headshot.jpg"
                        imageAlt="Jordan Gilroy"
                        mediumQuote
                    />
                    <Blockquote
                        quote="This <strong>superb book</strong> isn&apos;t just about how to price your work as a freelancer, but also how to know your worth as a professional in general.

<strong>Get your worth and value in order</strong>."

                        cite="Reiz Ariva, Freelance SEO"
                        imageName="rh-headshot.jpg"
                        imageAlt="Reiz Ariva"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Tom makes excellent points and provides tons of truly actionable tactics. <strong>10/10 would recommend!</strong>"

                        cite="Andra Zaharia, Freelance Content Marketer"
                        imageName="az-headshot.jpg"
                        imageAlt=" Andra Zaharia"
                        largeQuote
                    />
                    <Blockquote
                        quote="<strong>I&apos;m highly recommending this read</strong> not only to freelancers but <strong>to every value-creator</strong> out there!"

                        cite="Daniel Zettl, Senior Animator at Riot Games"
                        imageName="dz-headshot.jpg"
                        imageAlt="Daniel Zettl"
                        mediumQuote
                    />
                    <Blockquote
                        quote="<strong>I highly recommend this book</strong> to <strong>any freelancer</strong> looking to <strong>build confidence in their pricing structure</strong>."
                        cite="Jacob Lett, Freelance Developer"
                        imageName="jl-headshot.jpg"
                        imageAlt="Jacob Lett"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Even when you feel comfortable with what you charge, <strong>this book will give you the insight to improve</strong>."
                        cite="Gaya Kessler, Freelance JavaScript Developer"
                        imageName="gk-headshot.jpg"
                        imageAlt="Gaya Kessler"
                        mediumQuote
                    />
                    <Blockquote
                        quote="If you&apos;re a freelancer and struggling to price your services, <strong>go and buy this book</strong>."
                        cite="Suganthan Mohanadasan, Freelance SEO Consultant"
                        imageName="sm-headshot.jpg"
                        imageAlt="Suganthan Mohanadasan"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Every current and beginner freelancer should invest in this book. <strong>I highly recommend it!</strong>"
                        cite="Kyle Prinsloo, Freelancer and Teacher"
                        imageName="kp-headshot.jpg"
                        imageAlt="Kyle Prinsloo"
                        largeQuote
                    />
                    <Blockquote
                        quote="<strong>I wish Tom&apos;s book had been available 7 years ago</strong> when I started freelancing. It would have <strong>saved me from many mistakes</strong>."
                        cite="Burkhard Stubert, Independent Systems Consultant"
                        imageName="bs-headshot.jpg"
                        imageAlt="Burkhard Stubert"
                        mediumQuote
                    />
                    <Blockquote
                        quote="<strong>Every freelancer</strong> who wants to <strong>maximize their income</strong> should read this <strong>today</strong>"
                        cite="Paul Metcalfe, Freelancer"
                        imageName="pm-headshot.jpg"
                        imageAlt="Paul Metcalfe"
                        mediumQuote
                    />
                    <Blockquote
                        quote="I genuinely believe <strong>all freelancers should read this</strong>."
                        cite="Toby Osbourne, Software Engineer"
                        imageName="to-headshot.jpg"
                        imageAlt="Toby Osbourne"
                        largeQuote
                    />
                    <br />
                    <Button className="gumroad-button" to="https://gum.co/pcUlz?wanted=true"
                            icon="download" text="Buy eBook now for $39" color="green" external />
                </div>
                <h2>Book Sample</h2>
                <p><a href="https://pricing-freelance-projects.netlify.app/pricing-freelance-projects-sample.pdf">Click here</a> to see a 13-page sample from the PDF version of the book.</p>
                <h2>Contents</h2>
                <ol>
                    <li>Synopsis</li>
                    <li>Introduction</li>
                    <li>The Problem With Pricing Freelance Projects</li>
                    <li>The Fallacy Of Pricing Freelance Projects</li>
                    <li>A Focus On Value
                        <ol>
                            <li>The Meaning Of Value</li>
                            <li>The Benefits Of Using Value To Price</li>
                            <li>Obtaining A Value Mindset</li>
                            <li>Gauging Value</li>
                            <li>How Value Informs A Freelancer&apos;s Price</li>
                        </ol>
                    </li>
                    <li>Value For Freelancers
                        <ol>
                            <li>Increasing Baseline Value</li>
                            <li>Increasing Differential Value</li>
                            <li>Providing Added Value</li>
                            <li>Value Retention</li>
                            <li>Value Detection</li>
                        </ol>
                    </li>
                    <li>Value For Clients
                        <ol>
                            <li>Business Outcomes</li>
                            <li>Bought Time</li>
                            <li>Value Indicators</li>
                        </ol>
                    </li>
                    <li>Perceived Value</li>
                    <li>Actual Value</li>
                    <li>What&apos;s Fair</li>
                    <li>Pricing Methodologies For Freelancers
                        <ol>
                            <li>Hourly Billing</li>
                            <li>Daily Billing</li>
                            <li>Fixed-Pricing</li>
                            <li>Value-Based Pricing</li>
                            <li>Retainers</li>
                        </ol>
                    </li>
                    <li>Supplementary Pricing Assets
                        <ol>
                            <li>Estimates and Quotes</li>
                            <li>Proposals</li>
                            <li>Pre-Projects And Discovery</li>
                        </ol>
                    </li>
                    <li>The Best Method For The Job</li>
                    <li>Beyond The Method
                        <ol>
                            <li>Your Bottom Line</li>
                            <li>Deposits</li>
                            <li>Knowing Your Market Position</li>
                            <li>Assessing Your Circumstances</li>
                            <li>Understanding Your Prospect</li>
                            <li>Risk And Price</li>
                            <li>Trust</li>
                            <li>Safety Money</li>
                        </ol>
                    </li>
                    <li>Getting Paid What You&apos;re Worth
                        <ol>
                            <li>Justifying Your Price</li>
                            <li>Credibility</li>
                            <li>Confidence</li>
                            <li>Knowing Your Worth</li>
                            <li>Negotiation</li>
                            <li>Raising Your Price</li>
                            <li>Experimentation</li>
                            <li>The Right Price</li>
                        </ol>
                    </li>
                    <li>Pricing Preferences
                        <ol>
                            <li>Rules From A Decade Of Pricing Freelance Projects</li>
                            <li>Setting Your Pricing Preferences</li>
                        </ol>
                    </li>
                    <li>The Reality Of Pricing Freelance Projects</li>
                    <li>Acknowledgements</li>
                    <li>References</li>
                    <li>Appendix</li>
                </ol>
                <Button className="gumroad-button" to="https://gum.co/pcUlz?wanted=true" icon="download"
                        text="Buy eBook now for $39" color="green" external gumroadSingle />

                <h2>Free Updates For Life</h2>
                <p>Purchase of <strong>Pricing Freelance Projects</strong> comes with <strong>free updates for life</strong>.</p>

                <p>Any time I make an edit or addition to the book, you&apos;ll get it at no further cost.</p>
                <h2>Money-Back Guarantee</h2>
                <p>I only want your money if Pricing Freelance Projects provides you with value.</p>
                <p>If within 30-days of your purchase you find no value inside, send me an email and I&apos;ll refund you.</p>
                <h2>FAQs</h2>
                <h3>Who is this book for exactly?</h3>
                <p>
                    <strong>Pricing Freelance Projects</strong> is for <strong>freelancers</strong>, <strong>people thinking about going into freelancing</strong>, <strong>consultants</strong>, <strong>companies of one</strong>, <strong>entrepreneurs</strong>, <strong>people running small-agencies</strong>, <strong>graphic designers</strong>,
                    <strong>animators</strong>, <strong>illustrators</strong>, <strong>web developers</strong>, <strong>software engineers</strong>, <strong>writers</strong>, <strong>journalists</strong>, <strong>marketers</strong>, <strong>SEOs</strong> and
            more.
                </p>

                <p>It&apos;s for <strong>anyone who does client work</strong>, <strong>anyone who sells services or products</strong> and <strong>anyone with an interest
                    in how value informs price</strong>.</p>


                <h3>Does buying this book guarantee success?</h3>
               <p>Pricing Freelance Projects is the story of my experiences and I don&apos;t guarantee that you will see the
                   same level of success.</p>

                <p>Applying this knowledge requires individual effort and is dependant on your specific circumstances and
                    other external factors.</p>


                <h3>Have a specific question?</h3>
                <p>Send an email to <a href="mailto:hello@tomhirst.com?Subject=Pricing Freelance Projects Question">hello@tomhirst.com</a> with the details, I&apos;ll be <strong>happy to help</strong>.</p>
                <h2>What Now?</h2>
                <p><strong>Now&apos;s your chance</strong> to get <strong>a decade&apos;s worth of knowledge</strong> for a <strong>fraction of the cost</strong> that the lessons
                    learned could <strong>earn you</strong>.</p>

                <p>If reading this book gets you a <strong>higher price on your next project</strong>, it <strong>pays for itself</strong>.</p>

                <p><strong>Click the button below now</strong> to <strong>learn how to</strong> use value realistically to <strong>know what to charge</strong> and <strong>get paid what you&apos;re worth</strong>.</p>
                <Button className="gumroad-button" to="https://gum.co/pcUlz?wanted=true"
                        icon="download" text="Buy eBook now for $39" color="green" external />
            </Section>
        </Layout>
    );
};

PricingFreelanceProjectsPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

PricingFreelanceProjectsPage.defaultProps = {
    location: {},
};

export default PricingFreelanceProjectsPage;
