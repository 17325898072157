/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Stylesheets
 */
import './style.scss';

const ButtonGroup = ( { children } ) => (
    <div className="button-group">
        { children }
    </div>
);

ButtonGroup.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ButtonGroup;
