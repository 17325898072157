/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parser from 'html-react-parser';

/**
 * Internal dependencies
 */
import ShapedImage from '../shaped-image';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Blockquote = ( {
    cite, imageName, imageAlt, largeQuote, mediumQuote, quote, boxed, boxedWhite, narrow,
} ) => {
    const classes = classNames( 'blockquote', {
        'is-large-quote': largeQuote,
        'is-medium-quote': mediumQuote,
        'is-boxed': boxed,
        'is-boxed-white': boxedWhite,
        'is-narrow': narrow,
    } );

    return (
        <blockquote className={classes}>
            { imageName
            && <div className="blockquote-image"><ShapedImage name={imageName} alt={imageAlt} grayscale /></div> }
            <div className="blockquote-inner">
                <FontAwesomeIcon icon="quote-left" className="quote-mark" />
                { parser( quote ) }
                { cite && (
                    <cite className="blockquote-cite">
                        &ndash;
                        &nbsp;
                        { cite }
                    </cite>
                ) }
            </div>
        </blockquote>
    );
};

Blockquote.propTypes = {
    cite: PropTypes.string,
    imageName: PropTypes.string,
    largeQuote: PropTypes.bool,
    quote: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    mediumQuote: PropTypes.bool,
    narrow: PropTypes.bool,
};

Blockquote.defaultProps = {
    cite: '',
    imageName: '',
    imageAlt: '',
    largeQuote: false,
    mediumQuote: false,
    narrow: false,
};

export default Blockquote;
